<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

type DropdownItemId = number | null
type DropdownItem = { id: DropdownItemId; name: string }

const props = withDefaults(
  defineProps<{
    modelValue: DropdownItemId
    items: DropdownItem[]
    disabled?: boolean
    width?: string
  }>(),
  {
    disabled: false,
    width: '',
  }
)

const emits = defineEmits<{
  (e: 'update:modelValue', value: DropdownItemId): void
}>()

const open = ref(false)

const currentItem = computed({
  get: () => {
    return (
      props.items.find((item) => {
        return item.id === props.modelValue
      }) ?? { id: null, name: '' }
    )
  },
  set: (value: DropdownItem) => {
    open.value = false
    emits('update:modelValue', value.id)
  },
})

const dropdownRoot = ref(null)
const closeDropdown = () => {
  open.value = false
}
onClickOutside(dropdownRoot, closeDropdown)
</script>

<template>
  <div ref="dropdownRoot" class="width">
    <div>
      <button class="button dropdown-button width" :class="{ disabled }" @click="open = !open">
        <span class="name">{{ currentItem.name }}</span>
        <ExpandMoreIcon />
      </button>
    </div>
    <div v-if="!disabled && open" class="dropdown-menu-block">
      <div class="dropdown-content">
        <a v-for="item in items" :key="item.id ?? -1" class="dropdown-item item-height" @click="currentItem = item">
          {{ item.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  background-color: #f5f5f5;
}
.width {
  min-width: 160px;
  width: v-bind(width);
}
.item-height {
  height: 36px;
}
.dropdown-button {
  display: flex;
  justify-content: space-between;
  .name {
    overflow: hidden;
  }
}
.dropdown-menu-block {
  position: absolute;
  z-index: 10; // TODO: z-indexは変数管理したい
  max-height: 240px;
  overflow: auto;
  border: 1px solid #ccc;
}
</style>
